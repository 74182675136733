import React, { useRef, useState, useEffect } from "react";
import { useFetching } from "../hooks/useFetching";
import PostService from "../API/PostService";
import Loader from "../components/UI/Loader/Loader";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'
import "../styles/HtmlEditor.css";
import ReupPostForm from "../components/ReupPostForm";
import MyButton from "../components/UI/button/MyButton";
import MyInput from "../components/UI/input/MyInput";
import { Link, useParams, useHistory } from "react-router-dom";
import DOMPurify from 'dompurify';

const defaultFonts = [
  "Arial",
  "Comic Sans MS",
  "Courier New",
  "Impact",
  "Georgia",
  "Tahoma",
  "Trebuchet MS",
  "Verdana",
]

const sortedFontOptions = [
  "Logical",
  "Salesforce Sans",
  "Garamond",
  "Sans-Serif",
  "Serif",
  "Times New Roman",
  "Helvetica",
  ...defaultFonts,
].sort();

const PostIdPage = () => {
  const params = useParams();
  const history = useHistory();

  const [post, setPost] = useState({});
  const [inputUrl, setInputURL] = useState('')
  const [isFetchPage, setIsFetchPage] = useState(true);
  const [isYoutubePage, setIsYoutubePage] = useState(false);
  const [isReupForm, setIsReupForm] = useState(false);

  const [validationResult, setValidationResult] = useState(null);
  const [editorValue, setEditorValue] = useState("");
  const [youtubePreviewDiv, setYoutubePreviewDiv] = useState(null);
  const [previewHtml, setPreviewHtml] = useState("");

  
  const [fetchPostById, isLoading, error] = useFetching(async (id) => {
    const response = await PostService.getById(id);
    setPost(response.data);
    setEditorValue(post.content);
  });  

  const [fetchPostByUrl, isFetchLoading, fetchError] = useFetching(async (url) => {
    console.log(url)
    const response = await PostService.getByURL(url);
    setPost(response.data);

    setEditorValue(response.data.content);
    // Create a temporary div element
    const tempDiv = document.createElement('div');
    // Set the HTML content to the temporary div
    tempDiv.innerHTML = response.data.content;

    // Find the iframe element within the temporary div
    const iframeAllElements = tempDiv.querySelectorAll('iframe');
    const iframeElement = tempDiv.querySelector('iframe');
    if (iframeElement && iframeAllElements.length == 1) {
      setYoutubePreviewDiv(iframeElement.outerHTML)
    }
  });

  const [fetchYoutubeVideo, isFetchYoutubeLoading, fetchYoutubeError] = useFetching(async (url) => {
    console.log(url)
    const response = await PostService.getByYoutubeURL(url);
    setPost(response.data);
    console.log(response.data.content)
    setEditorValue(response.data.content);
  });
  

  useEffect(() => {
    if (params.id !== 'fetch' && params.id !== 'youtube') {
      setIsFetchPage(false);
      fetchPostById(params.id);
    } else if (params.id === 'youtube') {
      setIsFetchPage(false);
      setIsYoutubePage(true);
    }
  }, [params.id]);

  useEffect(() => {
    setPreviewHtml(getResultContents(editorValue));
    setPost({ ...post, content: editorValue });
  }, [editorValue]);

  const handleEditorChange = (value) => {
    if(!isFetchLoading){
      setEditorValue(value);
    }
  };

  const getResultContents = (content) => {
    let result_content = `<link href="https://cdn.jsdelivr.net/npm/suneditor@latest/dist/css/suneditor.min.css" rel="stylesheet">
    <div class="sun-editor-editable">
      ${content}
    </div>
    ${youtubePreviewDiv ? youtubePreviewDiv : ''}
    `;

    // Create a temporary div element
    const tempDiv = document.createElement('div');
    // Set the HTML content to the temporary div
    tempDiv.innerHTML = result_content;

    let iframes = tempDiv.querySelectorAll('iframe');
    console.log(iframes)
    // Check if there are at least two iframes
    if (iframes.length >= 2) {
        // Remove the second iframe
        iframes[1].parentNode.removeChild(iframes[1]);
    }
    result_content = tempDiv.outerHTML;

    return params.id === 'youtube' ? `<div>
      <link href="https://cdn.jsdelivr.net/npm/suneditor@latest/dist/css/suneditor.min.css" rel="stylesheet">
      <div class="sun-editor-editable">
        ${content}
      </div>
      <!-- wp:embed {"url":${post.link},"type":"video","providerNameSlug":"youtube","responsive":true,"className":"wp-embed-aspect-16-9 wp-has-aspect-ratio"} -->
<figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio"><div class="wp-block-embed__wrapper">
${post.link}
</div></figure>
<!-- /wp:embed -->
    </div>` : result_content;
  }

  const decodeHTMLEntities = (text) => {
    const element = document.createElement("textarea");
    element.innerHTML = text;
    return element.value;
  };

  const handleBack = () => {
    // Go back to the previous page
    history.goBack();
  };

  const doFetchPage = async () => {
    //setPost({})
    await fetchPostByUrl(inputUrl)
  }

  const doFetchYoutubeVideo = async () => {
    //setPost({})
    await fetchYoutubeVideo(inputUrl)
  }

  return (
    <div style={{ width: "100%", marginTop: "10px", padding: "10px" }}>
      {!isFetchPage && !isYoutubePage ? (
        <button className="back-button" onClick={handleBack}>
          Back
        </button>
      ) : 
        <div style={{ width: "100%", marginBottom: "10px"}}>
          {/* <form> */}
            <MyInput
              style={{
                textAlign: "left",
                marginTop: 30,
                // fontWeight: "bold",
                // fontSize: "18px",
              }}
              value={inputUrl} 
              // onChange={handleChangeInputUrl}
              onChange={(e) => setInputURL(e.target.value)}
              type="text"
              placeholder="Input URL to fetch the post"
            />

            {isYoutubePage ? 
              (
                <div>
                  <MyButton onClick={doFetchYoutubeVideo}>Get Youtube Summary</MyButton>
                </div>
              ) :
              <MyButton onClick={doFetchPage}>Get post</MyButton>
            }
          {/* </form> */}
        </div>
      }

      { isFetchLoading || isFetchYoutubeLoading
          ? <div style={{display:'flex', justifyContent:'center', marginTop:50}}><Loader /></div>
          : null
         }

      {
        (fetchError || fetchYoutubeError) &&
        <h3>Can not fetch this page. Please contact to supporter to check it...</h3>
     }

      {
        // (Object.keys(post).length > 0) ? (
          (!isFetchLoading) ? (
          <div>
            <ReupPostForm post={post} contentPost={editorValue} setContentPost={setEditorValue} setPost={setPost} validationResult={validationResult} setValidationResult={setValidationResult}  />

            <hr style={{ margin: "15px 0" }} />
            <MyInput
              style={{
                textAlign: "left",
                marginTop: 30,
                fontWeight: "bold",
                fontSize: "18px",
              }}
              value={decodeHTMLEntities(post.title)}
              onChange={(e) => setPost({ ...post, title: e.target.value })}
              type="text"
              placeholder="Title of the post"
            />

            <div className="html-editor-container">
              <div className="editor-container">
                <SunEditor
                  height="100%"
                  setContents={editorValue}
                  onChange={handleEditorChange}
                  setOptions={{
                    buttonList: [
                      ["undo", "redo"],
                      ["font", "fontSize"],
                      ['paragraphStyle', 'blockquote'],
                      [
                        "bold",
                        "underline",
                        "italic",
                        "strike",
                        "subscript",
                        "superscript",
                      ],
                      ["fontColor", "hiliteColor"],
                      ["align", "list", "lineHeight"],
                      ["outdent", "indent"],

                      ["table", "horizontalRule", "link", "image", "video"],
                      // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                      // ['imageGallery'], // You must add the "imageGalleryUrl".
                      ["fullScreen", "showBlocks", "codeView"],
                      ["preview", "print"],
                      ["removeFormat"],

                      // ['save', 'template'],
                      // '/', Line break
                    ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                    defaultTag: "div",
                    minHeight: "300px",
                    showPathLabel: false,
                    font: sortedFontOptions,
                  }}
                />
              </div>
              <div className="preview-container">
                <h3>Preview:</h3>
                <div dangerouslySetInnerHTML={{ __html: previewHtml }} />
              </div>
            </div>
          </div>
      ) : null}
    </div>
  );
};

export default PostIdPage;
