import React, { useState, useEffect } from "react";
import MyButton from "./UI/button/MyButton";
import MyInput from "./UI/input/MyInput";
import MySelect from "./UI/select/MySelect";
import Loader from "./UI/Loader/Loader";
import PostService from "../API/PostService";
import { useFetching } from "../hooks/useFetching";
import ResponseMatches from './ReupPostComponents/ResponseMatches';

const MINIMUM_CONTENT_WORD_COUNT = 600;

const ReupPostForm = ({ ...props }) => {
  const [selectedSite, setSelectedSite] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedCat, setSelectedCat] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [reupPostLink, setReupPostLink] = useState("");
  const [uploadedPost, setUploadedPost] = useState(false);
  const [contentWordCount, setContentWordCount] = useState(0);
  const [uploadPostSuccess, setUploadPostSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [force, setForcePush] = useState(false);
  const [validationResult, setValidationResult] = useState(null);

  const [sendReupPost, isLoading, error] = useFetching(
    async (post, site, selectedCat, selectedStatus, force) => {
      const response = await PostService.sendReupPost(
        post,
        site,
        selectedCat,
        selectedStatus,
        force
      );
      setUploadedPost(true);
      if (response.data.validationResult) {
        setValidationResult(response.data.validationResult);
        props.validationResult = response.data.validationResult;
        props.setValidationResult(response.data.validationResult);

        props.contentPost = response.data.newContent

        setContentWordCount(response.data.validationResult.wordCount);
        if (response.data.validationResult.isValid) {
          setReupPostLink(response.data.link);
          setUploadPostSuccess(true);
        }
      } else {
        setUploadPostSuccess(true);
        setReupPostLink(response.data.link);
      }
    },
    async (response) => {
      setUploadedPost(true);
      if(response.data?.message){
        setErrorMessage(response.data.message)
      }
    }
  );

  const [getCategoriesBySite, isCatLoading, catError] = useFetching(
    async (site) => {
      const response = await PostService.getCategoriesBySite(site);
      const categories = response.data.map((category) => {
        return { value: category.id, name: category.name };
      });
      setCategoriesList(categories);
    }
  );

  const addNewPost = async (e, force = false) => {
    e.preventDefault();
    setForcePush(force);

    props.post.content = removeHighlightSpans(props.post.content);
    await sendReupPost(props.post, selectedSite, selectedCat, selectedStatus, force);
  };

  const [generateContentCall, isGenerateLoading, generateError] = useFetching(
    async (postContent) => {
      const response = await PostService.postGenerateContent(postContent);

      props.contentPost = response.data.newContent

      props.setContentPost(props.contentPost)
      props.setPost({...props.post, content: props.contentPost, wordCount: response.data.wordCount})

      setContentWordCount(response.data.wordCount)
    }
  );

  const generateContent = async (e) => {
    e.preventDefault();
    await generateContentCall(props.contentPost);
  };

  useEffect(() => {
    if (validationResult && validationResult.listWordsMatch) {
      const highlightedText = highlightWords(props.contentPost, validationResult.listWordsMatch);

      props.contentPost = highlightedText
      props.setContentPost(props.contentPost)
    }
  }, [validationResult])

  useEffect(() => {
    async function fetchData() {
      if (selectedSite) {
        await getCategoriesBySite(selectedSite);
        // categories = categories.data.map(category =>{
        //     return { value: category.id, name: category.name }
        // })
        // setCategoriesList(categories)
      }
    }
    fetchData();
    setContentWordCount(props.post.wordCount);
  }, [selectedSite]); // Or [] if effect doesn't need props or state

  const LIST_SITES = [
    {
      value: 'CELEBRITY_ZULULION',
      name: 'CELEBRITY_ZULULION - celebrity.zululion.com'
    },
    {
      value: 'CELEBRITY_XINLOC',
      name: 'CELEBRITY_XINLOC - https://celebrity.xinloc.com'
    },
    { value: 'NEWSIN24H_COM', name: 'NEWSIN24H_COM - newsin24h.com' },
    {
      value: 'SPORTNEWSZULULION_COM',
      name: 'SPORTNEWSZULULION_COM - sportnews.zululion.com'
    },
    {
      value: 'FILMZULULION_COM',
      name: 'FILMZULULION_COM - https://film.zululion.com'
    },
    {
      value: 'FOXNEWS_ZULULION_COM',
      name: 'FOXNEWS_ZULULION_COM - https://foxnews.zululion.com/'
    },
    {
      value: 'FOX_ZULULION_COM',
      name: 'FOX_ZULULION_COM - https://fox.zululion.com/'
    },
    {
      value: 'DAILYNEWS_HOMEXTOP_COM',
      name: 'DAILYNEWS_HOMEXTOP_COM - https://dailynews.homextop.com/'
    },
    {
      value: 'NEWS_HOMEXTOP_COM',
      name: 'NEWS_HOMEXTOP_COM - https://news.homextop.com/'
    },
    {
      value: 'NEWS_TEAMXTOP_COM',
      name: 'NEWS_TEAMXTOP_COM - https://news.teamxtop.com/'
    },
    {
      value: 'FILM_PUREXTOP_COM',
      name: 'FILM_PUREXTOP_COM - https://film.purextop.com/'
    },
    {
      value: 'NEWSWAVE_ZULULION_COM',
      name: 'NEWSWAVE_ZULULION_COM - https://newswave.zululion.com/'
    },
    {
      value: 'FRESHNEWS_ZULULION_COM',
      name: 'FRESHNEWS_ZULULION_COM - https://freshnews.zululion.com/'
    },
    {
      value: 'NEWSBLITZ_ZULULION_COM',
      name: 'NEWSBLITZ_ZULULION_COM - https://newsblitz.zululion.com/'
    },
    {
      value: 'SNAPNEWS_ZULULION_COM',
      name: 'SNAPNEWS_ZULULION_COM - https://snapnews.zululion.com/'
    },
    {
      value: 'QUICKNEWS_ZULULION_COM',
      name: 'QUICKNEWS_ZULULION_COM - https://quicknews.zululion.com/'
    },
    {
      value: 'TMZ_ZULULION_COM',
      name: 'TMZ_ZULULION_COM - https://tmz.zululion.com/'
    },
    {
      value: 'ANIMALS_NEWSIN24H_COM',
      name: 'ANIMALS_NEWSIN24H_COM - https://animals.newsin24h.com/'
    },
    {
      value: 'HOTNEWS_TEAMXTOP_COM',
      name: 'HOTNEWS_TEAMXTOP_COM - https://hotnews.teamxtop.com/'
    },
    {
      value: 'BREAKINGNEWS_TEAMXTOP_COM',
      name: 'BREAKINGNEWS_TEAMXTOP_COM - https://breakingnews.teamxtop.com/'
    },
    {
      value: 'DAILYUPDATES_TEAMXTOP_COM',
      name: 'DAILYUPDATES_TEAMXTOP_COM - https://dailyupdates.teamxtop.com/'
    },
    {
      value: 'LATESTNEWS_TEAMXTOP_COM',
      name: 'LATESTNEWS_TEAMXTOP_COM - https://latestnews.teamxtop.com/'
    },
    {
      value: 'TODAYNEWS_TEAMXTOP_COM',
      name: 'TODAYNEWS_TEAMXTOP_COM - https://todaynews.teamxtop.com/'
    },
    {
      value: 'WORLDNEWS_TEAMXTOP_COM',
      name: 'WORLDNEWS_TEAMXTOP_COM - https://worldnews.teamxtop.com/'
    },
    {
      value: 'TOPSTORIES_HOMEXTOP_COM',
      name: 'TOPSTORIES_HOMEXTOP_COM - https://topstories.homextop.com/'
    },
    {
      value: 'LIVEUPDATES_HOMEXTOP_COM',
      name: 'LIVEUPDATES_HOMEXTOP_COM - https://liveupdates.homextop.com/'
    },
    {
      value: 'HEADLINENEWS_HOMEXTOP_COM',
      name: 'HEADLINENEWS_HOMEXTOP_COM - https://headlinenews.homextop.com/'
    },
    {
      value: 'NEWSTODAY_HOMEXTOP_COM',
      name: 'NEWSTODAY_HOMEXTOP_COM - https://newstoday.homextop.com/'
    },
    {
      value: 'NEWSFEED_HOMEXTOP_COM',
      name: 'NEWSFEED_HOMEXTOP_COM - https://newsfeed.homextop.com/'
    },
    {
      value: 'FASTNEWS_HOMEXTOP_COM',
      name: 'FASTNEWS_HOMEXTOP_COM - https://fastnews.homextop.com/'
    },
    {
      value: 'NEWSALERT_HOMEXTOP_COM',
      name: 'NEWSALERT_HOMEXTOP_COM - https://newsalert.homextop.com/'
    }
  ];

  const statusesList = [
    {
      value: "draft",
      name: "Draft",
    },
    {
      value: "publish",
      name: "Publish",
    },
  ];

  function highlightWords(content, matches) {
      // Copy original content to avoid mutations
      let highlightedContent = content;

      matches.forEach(word => {
        // Create a regex to find the word only if it is not already inside the specific span tag
        const regExp = new RegExp(`(\\b${word}\\b)(?!([^<]*?>))`, 'gi');

        // Function to check if the match is inside the specific span
        const isAlreadyHighlighted = (match, offset) => {
            const beforeMatch = highlightedContent.slice(0, offset + match.length);
            const spanStart = beforeMatch.lastIndexOf('<span style="background-color: yellow;">');
            const spanEnd = beforeMatch.lastIndexOf('</span>');

            // Check if the last <span> tag before this match starts and if there is no closing tag before this match
            return spanStart > spanEnd;
        };

        highlightedContent = highlightedContent.replace(regExp, (match, p1, offset) => {
            // Only replace if the match is not already highlighted
            if (!isAlreadyHighlighted(match, offset)) {
                return `<span style="background-color: yellow;">${match}</span>`;
            }
            return match;
        });
    });


      return highlightedContent;
    }

    function removeHighlightSpans(content) {
      // Remove specific span start tags
      content = content.replace(/<span style="background-color: yellow;">/g, '');
  
      // Remove all span end tags
      content = content.replace(/<\/span>/g, '');
  
      return content;
    }

    // Function to get matches from raws based on critical fields
    // function getCriticalMatches(validationResult) {
    //     const criticalFields = validationResult.criticalFields;
    //     const raws = validationResult.raws;
    //     let matches = [];

    //     // Collect all matches related to critical fields
    //     criticalFields.forEach(field => {
    //         if (raws[field] && raws[field].matches) {
    //             raws[field].matches.forEach(match => {
    //                 matches.push({
    //                     start: match.start,
    //                     end: match.end,
    //                     match: match.match
    //                 });
    //             });
    //         }
    //     });

    //     return matches;
    // }

  return (
    <div className="reup-post">
      <h3>Re-up post options:</h3>
      <form>
        <label> Select target site: </label>
        <MySelect
          value={selectedSite}
          onChange={(selectedSite) => setSelectedSite(selectedSite)}
          defaultValue="Select site to reup..."
          options={LIST_SITES}
          isRequired={true}
        />

        <label> Select category: </label>
        <MySelect
          value={selectedCat}
          onChange={(selectedCat) => setSelectedCat(selectedCat)}
          defaultValue="Select category to reup..."
          options={categoriesList}
          isRequired={true}
        />

        <label> Select status: </label>
        <MySelect
          value={selectedStatus}
          onChange={(selectedStatus) => setSelectedStatus(selectedStatus)}
          defaultValue="Select site to reup..."
          options={statusesList}
          isRequired={true}
        />
        {/* <MyInput 
            // value={post.title}
            // onChange={e => setPost({...post, title: e.target.value})}
            type='text' placeholder='Title of the post' />

            <MyInput 
            // value={post.body}
            // onChange={e => setPost({...post, body: e.target.value})}
            type='text' placeholder='Description of the post' /> */}

        <MyButton disabled={!selectedSite && !selectedCat && !selectedStatus} onClick={e => addNewPost(e)}>
          Upload post with validation
        </MyButton>

        <MyButton disabled={!selectedSite && !selectedCat && !selectedStatus} onClick={e => addNewPost(e, true)}>
          Force upload post (no validation)
        </MyButton>

        { contentWordCount < MINIMUM_CONTENT_WORD_COUNT && (
          <MyButton onClick={e => generateContent(e)}>
            Generate content (from Open AI)
          </MyButton>)
        }
        

      </form>

      {isCatLoading || isLoading || isGenerateLoading ? (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
        >
          <Loader />
        </div>
      ) : null}
      <p style={{ margin: "10px 0" }}>Required minimum word count: <strong>600</strong></p>
      {catError && (
        <h3>
          Can not get list of categories. Please contact to supporter to check
          it...
        </h3>
      )}


      { uploadedPost && (error || !uploadPostSuccess) && (
        <>
        <h3>
          [FAIL] Uploading post was FAIL!. Please check issue as belows:
        </h3>
        {errorMessage && <h4>{errorMessage}</h4>}
        </>
      )}
      {uploadedPost && uploadPostSuccess && !error && (
        <>
          <h2>
            Post's uploaded SUCCESS!
          </h2>
          {reupPostLink ? (
            <div className="reup-post-result" style={{ margin: "10px" }}>
              <label>Result link: </label>
              {reupPostLink ? (
                <a href={reupPostLink} target="__blank">
                  {" "}
                  {reupPostLink}{" "}
                </a>
              ) : (
                ""
              )}
            </div>
          ) : null}
        </>
      )}

      {<ResponseMatches response={validationResult} wordCount={contentWordCount}/>}

    </div>
  );
};

export default ReupPostForm;
