import axios from 'axios'

const original_url = process.env.ORIGINAL_URL
export default class PostService {
    static async getAll(limit = 10, page = 1, title, query, site, from_date, to_date) {
        console.log({
            _limit: limit,
            _page: page,
            site: site,
            title: title,
            query: query,
            from_date: from_date,
            to_date: to_date
        })
        const response = await axios.get('https://dev.stdata.space/v1/posts', {
            params: {
                _limit: limit,
                _page: page,
                title: title,
                site: site,
                query: query,
                from_date: from_date,
                to_date: to_date
            }
        })
        return response
    }

    static async getById(id){
        const response = await axios.get('https://dev.stdata.space/v1/posts/'+id)
        return response
    }

    static async postGenerateContent(content){
        const requestBody = {
            content
        }
        const response = await axios.post('https://dev.stdata.space/v1/posts/generateContent',requestBody)
        return response
    }


    static async getByURL(url){
        const requestBody = {
            url: url
          };

        const response = await axios.get('https://dev.stdata.space/v1/posts/fetch', {
            params: requestBody
          })

          console.log(response)
        return response
    }

    static async getByYoutubeURL(video_url){
        const requestBody = {
            url: video_url
          };

        const response = await axios.get('https://dev.stdata.space/v1/posts/youtube-with-gpt', {
            params: requestBody
          })
        return response
    }

    static async getCommentsByPostId(id){
        const response = await axios.get(`https://jsonplaceholder.typicode.com/posts/${id}/comments`)
        return response
    }

    static async sendReupPost(post, site, category_id, status, force){
        const requestBody = {
            post, site, category_id, status, force
        }
        const response = await axios.post('https://dev.stdata.space/v1/posts/reup', requestBody)
        return response
    }

    static async getCategoriesBySite(site){
        const response = await axios.get(`https://dev.stdata.space/v1/posts/categories`, {
            params: { site }
        })
        return response
    }

    static async getSites(){
        const response = await axios.get(`https://dev.stdata.space/v1/sites`)
        return response
    }
}
