import React from 'react';
import './ResponseMatches.css';

const ResponseMatches = ({ response,wordCount }) => {
    const categories = [
        { key: 'profanity', label: { en: 'Profanity', vi: 'Từ tục tỉu' } },
        { key: 'personal', label: { en: 'Personal', vi: 'Cá nhân' } },
        { key: 'link', label: { en: 'Link', vi: 'Liên kết' } },
        { key: 'medical', label: { en: 'Medical', vi: 'Y tế' } },
        { key: 'weapon', label: { en: 'Weapon', vi: 'Vũ khí' } },
        { key: 'extremism', label: { en: 'Extremism', vi: 'Chủ nghĩa cực đoan' } },
        { key: 'drug', label: { en: 'Drug', vi: 'Ma túy' } },
        { key: 'self-harm', label: { en: 'Self-Harm', vi: 'Tự tử' } },
        { key: 'violence', label: { en: 'Violence', vi: 'Bạo lực' } },
        { key: 'content-trade', label: { en: 'Content Trade', vi: 'Giao dịch nội dung' } },
        { key: 'money-transaction', label: { en: 'Money Transaction', vi: 'Giao dịch tiền tệ' } },
        { key: 'spam', label: { en: 'Spam', vi: 'Quảng cáo rác' } }
    ];

    if(response){
        var criticalFields = response.criticalFields || [];
        var warningFields = response.warningFields || [];
    }

    const renderMatches = (categoryKey, matches) => {
        return matches.map((match, index) => (
            <li key={`${categoryKey}-${index}`}>
                Nhóm {categories.find(cat => cat.key === categoryKey).label.vi} ({categories.find(cat => cat.key === categoryKey).label.en}): Từ "{match.match}" | Loại "{match.type}" | Vị trí "{match.start}-{match.end} {match.intensity && <span> - Mức độ: {match.intensity}</span>}"
            </li>
        ));
    };

    return (
        <div className="response-matches">
            <p>Post's word count: <span style={{ fontWeight: 'bold', fontSize: '20px' }}>{response ? response.wordCount : wordCount}</span></p>
            
            {response && ( <>
                <h3>Critical content issues:</h3>
                <ul>
                    {response.rules.map((rule, index) => (
                        <li key={index}>{rule.message}</li>
                    ))}
                </ul>
                <ul>
                    {criticalFields.map(field => (
                        response.raws[field].matches.length > 0 && renderMatches(field, response.raws[field].matches)
                    ))}
                </ul>
    
                <h3>Warning content issues:</h3>
                <ul>
                    {warningFields.map(field => (
                        response.raws[field].matches.length > 0 && renderMatches(field, response.raws[field].matches)
                    ))}
                </ul>
                </>
            )}
            
        </div>
    );
};

export default ResponseMatches;
